<template>
  <div class="home">
    <h1>Demo 列表</h1>
    <p>点击下方连接前往对应demo，代码在对应的路由中</p>
    <h3>播放器相关</h3>
    <div @click="handleClick">视频</div>
    <ul>
      <li>
        <router-link to="/player/puPlayer"> 视频</router-link>
      </li>
      <li>
        <router-link to="/player/intercom"> 对讲</router-link>
      </li>
      <li>
        <router-link to="/player/conference"> 会议</router-link>
      </li>
      <li>
        <router-link to="/player/websocketConference"> websocket会议</router-link>
      </li>
      <li>
        <router-link to="/player/playback"> 回放</router-link>
      </li>
    </ul>
    <h3>其他</h3>
    <ul>
      <li>
        <router-link to="/others/subscribe"> 订阅</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {
    msg: String,
  },
  methods: {
    handleClick() {
      const routeData = this.$router.resolve({
        path: '/vedio', // 替换为你想要跳转到的路径
        query: {id: 123}// 可以添加查询参数等
      });
      window.open(routeData.href, '_blank');
      // this.$router.push({name: 'Player', query: {id: 123}})
    }
  },
};
</script>

<style scoped lang="less">
.home {
  text-align: center;

  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
}</style>
